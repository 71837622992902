import BaseApp from "./baseapp";
import {
  MainApp,
} from "./mainapp";
import JournalManager from "./journalmanager";
import AnalysisResult from './../components/analysisresult.jsx';
import React from "react";
import {
  createRoot,
} from "react-dom/client";
import Split from 'split.js';

export default class JournalHelper {
  app: MainApp;
  journalManager: JournalManager;

  journal_list_new_button = document.querySelector('.journal_list_new_button') as HTMLButtonElement;
  journal_list_view = document.querySelector('.journal_list_view') as HTMLDivElement;
  journal_details_name = document.querySelector('.journal_details_name') as HTMLDivElement;
  journal_details_name_edit_button = document.querySelector('.journal_details_name_edit_button') as HTMLButtonElement;
  journal_entry_list_container = document.querySelector('.journal_entry_list_container') as HTMLDivElement;
  journal_tab_tag_select = document.querySelector(".journal_tab_tag_select") as HTMLSelectElement;
  entries_count_view = document.querySelector(".entries_count_view") as HTMLDivElement;
  journal_template_list_container = document.querySelector(".journal_template_list_container") as HTMLDivElement;
  journal_template_editor_container = document.querySelector(".journal_template_editor_container") as HTMLDivElement;
  viewSplitter = Split([this.journal_template_list_container, this.journal_template_editor_container],
    {
      sizes: [50, 50],
      direction: 'vertical',
      minSize: 100,
      gutterSize: 8,
    });
  journal_list_container = document.querySelector('.journal_list_container') as HTMLDivElement;
  journal_entry_container = document.querySelector('.journal_entry_container') as HTMLDivElement;
  journalSplitter = Split([this.journal_list_container, this.journal_entry_container],
    {
      sizes: [25, 75],
      direction: 'horizontal',
      minSize: 100,
      gutterSize: 8,
    });

  constructor(app: MainApp) {
    this.app = app;
    this.journalManager = new JournalManager(this.app);
    this.journalManager.getJournalCard = this.getJournalCard.bind(this);
    this.journalManager.getEntryCard = this.getEntryCard.bind(this);
    this.journalManager.handleJournalFeedCallback = this.updateJournalFeed.bind(this);
    this.journalManager.journalFeedView = this.journal_list_view;
    this.journalManager.entryFeedView = this.journal_entry_list_container;
    this.journalManager.tagSelectList = this.journal_tab_tag_select;
    this.journalManager.entriesCountView = this.entries_count_view;

    this.journal_list_new_button.addEventListener('click', async () => {
      const newName = prompt("New Journal Name", "My Journal");
      if (!newName) return;
      this.journalManager.addJournal(newName);
    });

    this.journal_details_name_edit_button.addEventListener('click', async () => this.updateSelectedJournalName());

    this.journal_tab_tag_select.addEventListener('input', async () => {
      this.journalManager.tagFilter = this.journal_tab_tag_select.value;
      this.app.saveProfileField("journalTagFilter", this.journalManager.tagFilter);
      this.journalManager.load(true);
    });
  }
  async load() {
    this.journalManager.load();
  }
  async updateSelectedJournalName() {
    const journalData = this.journalManager.journalCache[this.journalManager.journalId];
    if (!journalData) {
      alert("No Journal Selected");
      return;
    }
    let newName = prompt("New Journal Name", journalData.journalName);
    if (newName !== null) {
      newName = newName.trim().replace(/[\W]+/g, " ");
      newName = newName.trim().substring(0, 30);

      this.journalManager.saveJournalField("journalName", newName);
    }
  }
  paint() {
    this.updateJournalFeed();
  }
  updateJournalFeed() {
    const journalData = this.journalManager.journalCache[this.journalManager.journalId];
    if (!journalData) {
      this.journal_details_name.innerHTML = "No Journal Selected";
    } else {
      this.journal_details_name.innerHTML = journalData.journalName;
    }
  }
  getJournalCard(doc: any) {
    const data = doc.data();

    let ownerClass = "";
    let selectedClass = "";
    if (data.creator === this.app.uid) ownerClass += " journal_feed_owner_user";
    else ownerClass += " journal_feed_shared_user";
    if (doc.id === this.journalManager.journalId) selectedClass += " journal_list_item_selected";

    const timeStr = BaseApp.showGmailStyleDate(new Date(data.edited), true);
    const html = `<a href="#"
         class="journal_list_item rounded m-1 flex flex-row border border-gray-300 ${ownerClass}${selectedClass}" data-id="${doc.id}">
        <div class="flex-1 p-1">${data.journalName}</div><div class="p-1">${timeStr}</div>
      </a>`;
    const ctl = document.createElement("div");
    ctl.innerHTML = html;
    const card: any = ctl.children[0];
    return card;
  }
  getEntryCard(doc: any) {
    const data = doc.data();
    let ownerClass = "";
    if (data.creator === this.app.uid) ownerClass += " journal_feed_owner_user";
    else ownerClass += " journal_feed_shared_user";

    const tags = data.entryTags.join(", ");
    let timeStr = BaseApp.showGmailStyleDate(new Date(data.created), true);
    const html = `<div class="journal_entry_list_item block relative rounded m-1 p-2 border border-gray-300 ${ownerClass}"
        data-id="${doc.id}">
        <div class="journal_entry_list_item_text">${data.entryText}</div>
        <div class="flex flex-row">
          <div class="journal_entry_list_item_meta p-1">${timeStr}</div>
          <div class="flex-1 p-1">${tags}</div>
          <button class="delete_button p-2" data-id="${doc.id}">x</button>
        </div>
        <div class="metrics_output">
        </div>
      </div>`;
    const ctl = document.createElement("div");
    ctl.innerHTML = html;
    const card: any = ctl.children[0];


    card.analysisResultDisplay = React.createElement(AnalysisResult, {
      hooks: {},
    });
    card.analysisResultDisplay.props.hooks.processRawResultstoCompact =
      (analysisResults: any[]) => this.app.processRawResultstoCompact(analysisResults);
    const metrics_side_panel = card.querySelector(".metrics_output");
    createRoot(metrics_side_panel).render(card.analysisResultDisplay);

    return card;
  }
}