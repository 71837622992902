import BaseApp from "./baseapp";
import {
    MainApp,
} from "./mainapp";
import Mustache from 'mustache';

export default class AdjustTabHelper {
    app: MainApp;
    analysisRunning = false;
    journal_id_select_adjust_view = document.querySelector('.journal_id_select_adjust_view') as HTMLSelectElement;
    save_to_journal_btn = document.querySelector('.save_to_journal_btn') as HTMLButtonElement;
    prompt_input_textarea = document.querySelector(".prompt_input_textarea") as HTMLTextAreaElement;
    tag_input_adjust_view = document.querySelector('.tag_input_adjust_view') as HTMLInputElement;
    adjust_tags_datalist = document.querySelector('#adjust_tags_datalist') as HTMLDataListElement;
    adjust_journal_entry_paging = document.querySelector(".adjust_journal_entry_paging") as HTMLDivElement;
    entries_count_view = document.querySelector(".entries_count_view") as HTMLDivElement;
    prompt_submit_button = document.querySelector(".prompt_submit_button") as HTMLButtonElement;
    embeddingSizeLimit = 30000;

    constructor(app: MainApp) {
        this.app = app;

        this.journal_id_select_adjust_view.addEventListener('change', async () => {
            this.app.journalManager.journalId = this.journal_id_select_adjust_view.value;
            this.app.journalManager.load(true);
            this.app.saveProfileField("selectedAdjustJournalId", this.app.journalManager.journalId);
        });

        this.save_to_journal_btn.addEventListener('click', async () => this.markJournalEntry());

        this.prompt_input_textarea.addEventListener('change', () => this.app.saveProfileField("sourceMessageInput", this.prompt_input_textarea.value));
        this.tag_input_adjust_view.addEventListener('change', () => this.app.saveProfileField("selectedAdjustJournalTag", this.tag_input_adjust_view.value));

        this.prompt_submit_button.addEventListener("click", async () => this.runMetricsOnText());
    }
    async load() {
        this.app.journalManager.getEntryCard = this.getEntryCard.bind(this);
        this.app.journalManager.journalSelectList = this.journal_id_select_adjust_view;
        this.app.journalManager.tagDataList = this.adjust_tags_datalist;
        this.app.journalManager.entryPagingView = this.adjust_journal_entry_paging;
        this.app.journalManager.entriesCountView = this.entries_count_view;
        this.app.journalManager.load();
    }
    paint() {
        const currentTag = this.app.profile?.selectedAdjustJournalTag || "";
        this.tag_input_adjust_view.value = currentTag;

        this.app.getFieldFromProfile(this.prompt_input_textarea, "sourceMessageInput");
    }
    async markJournalEntry() {
        const text = this.prompt_input_textarea.value.trim();
        const tags = this.tag_input_adjust_view.value.split(",").map((x: string) => x.trim()).filter((x: string) => x.length > 0);
        const meta = {
            type: "text",
            source: "adjust",
            event: "mark",
        };
        await this.app.journalManager.saveJournalEntry(text, tags, meta);
    }
    getEntryCard(doc: any) {
        const data = doc.data();
        let ownerClass = "";
        if (data.creator === this.app.uid) ownerClass += " journal_feed_owner_user";
        else ownerClass += " journal_feed_shared_user";

        let timeStr = BaseApp.showGmailStyleDate(new Date(data.created), true);
        const html = `<div class="journal_entry_list_item block rounded m-1 p-2 border border-gray-300 ${ownerClass}"
          data-id="${doc.id}">
          <div class="journal_entry_list_item_text">${data.entryText}</div>
          <div class="journal_entry_list_item_meta">${timeStr}</div>
        </div>`;
        const ctl = document.createElement("div");
        ctl.innerHTML = html;
        const card: any = ctl.children[0];
        return card;
    }
    async runMetricsOnText() {
        const message = this.prompt_input_textarea.value.trim();
        if (!message) return alert("no message provided");
        if (this.analysisRunning) {
            if (!confirm("Analysis is already running. Are you sure you want to start a new analysis?")) {
                return;
            }
        }
        this.analysisRunning = true;
        document.body.classList.add("analysis_running");
        const analysisResult = await this.runAnalysisPrompts(message) || null;

        const tags = this.tag_input_adjust_view.value.split(",").map((x: string) => x.trim()).filter((x: string) => x.length > 0);
        const meta = {
            type: "text",
            source: "adjust",
            event: "analyze",
            result: analysisResult,
        };

        await this.app.journalManager.saveJournalEntry(message, tags, meta);
        document.body.classList.remove("analysis_running");

        this.analysisRunning = false;
    }
    async runAnalysisPrompts(text: string, promptToUse = null) {
        text = text.slice(0, this.embeddingSizeLimit);
        const runDate = new Date().toISOString();

        let prompts: any = [];
        const analysisPrompts = await this.app.getAnalysisPrompts();
        const selectedAnalysisSets = this.app.profile.selectedAnalysisSets || [];
        if (promptToUse) {
            prompts = [promptToUse];
        } else if (selectedAnalysisSets) {
            const localPrompts = analysisPrompts.filter((prompt: any) => selectedAnalysisSets.indexOf(prompt.setName) !== -1);
            localPrompts.forEach((prompt: any) => {
                prompts.push(prompt);
            });
        }

        const runPrompt = async (prompt: any, text: string) => {
            let fullPrompt = await this.sendPromptForMetric(prompt.template, text);
            let result = await this.app.processPromptWithAPI(fullPrompt);
            return {
                prompt,
                result,
            };
        };

        let promises: any[] = [];
        for (let prompt of prompts) {
            promises.push(runPrompt(prompt, text));
        }

        let results = await Promise.all(promises);
        let historyEntry = {
            text,
            results,
            runDate,
        };

        return historyEntry;
    }
    async sendPromptForMetric(promptTemplate: string, query: string) {
        try {
            const q = query.slice(0, this.embeddingSizeLimit);
            let result = Mustache.render(promptTemplate, { query: q });
            return result;
        } catch (error) {
            console.log(promptTemplate, query, error);
            return `{
            "contentRating": -1
          }`;
        }
    }
}
