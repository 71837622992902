import {
  encode,
} from "gpt-tokenizer";
import AccountHelper from "./accounthelper";
import {
  GoogleAuthProvider,
  signInWithPopup,
  getAuth,
  signInAnonymously,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signOut,
} from "firebase/auth";
import {
  doc,
  setDoc,
  getDoc,
  onSnapshot,
  getFirestore,
  collection,
  addDoc,
} from "firebase/firestore";
import {
  getApp,
} from "firebase/app";
import {
  createRoot,
} from "react-dom/client";
import ReactHeader from "../components/header.jsx";
import ReactFooter from "../components/footer.jsx";
import React, { ReactElement } from "react";

/** Base class for all pages - handles authorization and low level routing for api calls, etc */
export default class BaseApp {
  timeSinceRedraw = 300;
  feedLimit = 10;
  showLoginModal = true;
  deferredPWAInstallPrompt: any = null;
  projectId = getApp().options.projectId;
  basePath = `https://us-central1-${this.projectId}.cloudfunctions.net/`;
  urlParams = new URLSearchParams(window.location.search);
  muted = false;
  uid: any = null;
  profile: any = null;
  profileSubscription: any = null;
  profileInited = false;
  profileDefaulted = false;
  mute_button: any = null;
  verboseLog = false;
  rtdbPresenceInited = false;
  userPresenceStatus: any = {};
  userDocumentStatus: any = {};
  documentsLookup: any = {};
  userPresenceStatusRefs: any = {};
  userDocumentStatusRefs: any = {};
  memberUpdateTimeouts: any = {};
  userStatusDatabaseRef: any;
  documentStatusDatabaseRef: any;
  sessionDocumentData: any = null;
  usageWatchInited: any = null;
  sessionDeleting = false;
  isSessionApp = false;
  // rtdbInstance = getDatabase(getApp());

  documentId = "";
  memberRefreshBufferTime = 500;
  standard_header_bar_container: any = document.querySelector(".standard_header_bar_container");
  standard_footer_bar_container: any = document.querySelector(".standard_footer_bar_container");

  html_body_container: any = document.querySelector(".main_container");
  themeIndex = 0;
  buy_credits_cta_btn: any = document.querySelector(".buy_credits_cta_btn");
  tokenizedStringCache: any = {};
  account_status_display: any;

  /**
 * @param { boolean } addFooter add footer (true by default)
 */
  constructor() {
    if (this.standard_header_bar_container) {
      const ele: ReactElement = React.createElement(ReactHeader, {
        hooks: {},
      });
      createRoot(this.standard_header_bar_container).render(ele);
    }
    if (this.standard_footer_bar_container) {
      const ele: ReactElement = React.createElement(ReactFooter, {
        hooks: {},
      });
      ele.props.hooks.googleLogin = (e: Event) => {
        e.preventDefault();
        this.authGoogleSignIn();
      };
      ele.props.hooks.signOut = (e: Event) => {
        e.preventDefault();
        this.authSignout();
      };
      createRoot(this.standard_footer_bar_container).render(ele);
    }

    window.addEventListener("beforeinstallprompt", (e: any) => {
      e.preventDefault();
      this.deferredPWAInstallPrompt = e;
    });

    if (location.hostname === "localhost") this.basePath = `http://localhost:5001/${this.projectId}/us-central1/`;

    getAuth().onAuthStateChanged((u: any) => this.authHandleEvent(u));
    this.signInWithURL();

    document.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.code === 'Escape') this.closeModals();
    });

    this.themeIndex = BaseApp.initDayMode();
    document.body.classList.add("body_loaded");
    this.load();
  }
  /** */
  async handleContactRequest() {
    console.log("contact us sent");
    const name = (document.getElementById("name") as any).value;
    const email = (document.getElementById("email") as any).value;
    const message = (document.getElementById("message") as any).value;
    const organization = (document.getElementById("organization") as any).value;
    const reason = (document.getElementById("reason") as any).value;
    const mailRef = collection(getFirestore(), `email`);
    await addDoc(mailRef, {
      to: ["sam.huelsdonk@gmail.com", "lhoang91@gmail.com"],
      message: {
        subject: `Contact Request from ${email} - Unacog`,
        text: `name: ${name}\nemail: ${email}\nmessage:${message}\norganization:${organization}\nreason:${reason}`,
      }
    });
    location.reload();
  }
  /** asynchronous loads - data setup  */
  async load() {
    this.authUpdateStatusUI();
  }
  /** reads a json file async and sets window.varName to it's value
   * @param { string } path url to json data
   * @return { any } file contents or {}
   */
  static async readJSONFile(path: string): Promise<any> {
    try {
      const response = await fetch(path);
      return await response.json();
    } catch (e) {
      console.log("ERROR with download of " + path, e);
      return {};
    }
  }
  /** Paints UI display/status for user profile based changes */
  authUpdateStatusUI() {
    if (getAuth().currentUser) {
      if (document.body.dataset.creator === getAuth().currentUser?.uid) {
        document.body.classList.add("user_editable_record");
      }
    }

    if (this.profile) {
      this.updateUserStatus();
      this.updateUserNamesImages();
      this.initUsageWatch();
    }
  }
  /** */
  initUsageWatch() {
    if (this.usageWatchInited) return;
    this.usageWatchInited = true;
    const credits_left = document.querySelector(".credits_left");

    if (credits_left) {
      AccountHelper.accountInfoUpdate(this, (usageData: any) => {
        const availableBalance = usageData.availableCreditBalance;
        credits_left.innerHTML = Math.floor(availableBalance) + "<br><span>Credits</span>";
      });
    }
  }
  /** firebase authorization event handler
   * @param { any } user logged in user - or null if not logged in
   */
  async authHandleEvent(user: any) {
    // ignore unwanted events
    if (user && this.uid === user.uid) {
      return;
    }
    if (user) {
      this.uid = <string>getAuth().currentUser?.uid;
      document.body.classList.add("app_signed_in");
      document.body.classList.remove("app_signed_out");
      if (getAuth().currentUser?.isAnonymous) document.body.classList.add("signed_in_anonymous");

      await this._authInitProfile();
    } else {
      this.uid = null;
      document.body.classList.remove("app_signed_in");
      document.body.classList.add("app_signed_out");
      this.authUpdateStatusUI();
    }

    document.body.classList.add("auth_inited");
    return;
  }
  /** setup watch for user profile changes */
  async _authInitProfile() {
    if (this.profileInited) return;

    const docRef = doc(getFirestore(), `Users/${this.uid}`);
    this.profileSubscription = onSnapshot(docRef, async (snapshot: any) => {
      this.profile = snapshot.data();
      if (!this.profile) await this._authCreateDefaultProfile();
      this.profileInited = true;
      this.authUpdateStatusUI();
    });
  }
  /** create default user profile record and overwrite to database without merge (reset)
   * @param { string } displayName passed in name (ie google)
   * @param { string } displayImage passed in image (ie google)
  */
  async _authCreateDefaultProfile(displayName = "", displayImage = "") {
    if (this.profileDefaulted) return;
    this.profile = {
      displayName,
      displayImage,
    };

    this.profileDefaulted = true;
    const docRef = doc(getFirestore(), `Users/${this.uid}`);
    await setDoc(docRef, this.profile);
  }
  /** update user auth status, username/email etc */
  updateUserStatus() {
    const menu_profile_user_image_span = document.querySelector(".menu_profile_user_image_span");
    if (menu_profile_user_image_span) menu_profile_user_image_span.setAttribute("uid", this.uid);
  }
  /** google sign in handler */
  async authGoogleSignIn() {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      "prompt": "select_account",
    });
    const loginResult: any = await signInWithPopup(getAuth(), provider);
    if (loginResult.additionalUserInfo && loginResult.additionalUserInfo.profile && loginResult.user.uid) {
      this.uid = loginResult.user.uid;

      const docRef = doc(getFirestore(), `Users/${this.uid}`);
      const profile = await getDoc(docRef);
      let data = profile.data();
      if (!data) data = {};
      let displayName = data.displayName;
      let displayImage = data.displayImage;
      if (!displayName) displayName = "";
      if (!displayImage) displayImage = "";
      if (!profile.data() || !displayName || !displayImage) {
        const picture = !displayImage ? loginResult.additionalUserInfo.profile.picture : displayImage;
        const name = !displayName ? loginResult.additionalUserInfo.profile.name : displayName;
        await this._authCreateDefaultProfile(name, picture);
      }
    }
    setTimeout(() => {
      location.reload();
    }, 20);
  }
  /** anonymous sign in handler
   * @param { any } e dom event - preventDefault is called if passed
   */
  async signInAnon(e: any) {
    e.preventDefault();
    await signInAnonymously(getAuth());
    /*
    setTimeout(() => {
      location.reload();
    }, 1);
    */
    return true;
  }
  /** for use on page load - tests if a signIn token was included in the URL */
  signInWithURL() {
    if (isSignInWithEmailLink(getAuth(), location.href) !== true) return;

    let email = window.localStorage.getItem("emailForSignIn");
    if (!email) email = window.prompt("Please provide your email for confirmation");
    if (!email) return;

    signInWithEmailLink(getAuth(), email, location.href)
      .then(() => {
        window.localStorage.removeItem("emailForSignIn");
        location.reload();
      })
      .catch((e: any) => console.log(e));
  }
  /** returns text value for time since Now, i.e. 3 mins ago
   * @param { Date } date value to format
   * @param { boolean } showSeconds show counting seconds
   * @return { string } formatted string value for time since
   */
  timeSince(date: Date, showSeconds = false): string {
    let seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
    seconds = Math.max(seconds, 0);

    let interval = seconds / 31536000;
    if (interval > 1) return Math.floor(interval) + ` yr`;

    interval = seconds / 2592000;
    if (interval > 1) return Math.floor(interval) + ` M`;

    interval = seconds / 86400;
    if (interval > 1) return Math.floor(interval) + ` d`;

    interval = seconds / 3600;
    if (interval > 1) return Math.floor(interval) + ` hr`;

    if (showSeconds) return Math.floor(seconds) + " s";

    interval = seconds / 60;
    if (interval > 1) return Math.floor(interval) + ` m`;

    return "now";
  }
  /** get gmail like past date
   * @param { Date } dt date to format
   * @param { boolean } amFormat use am and pm for time if true
   * @return { string } formatted date
  */
  static showGmailStyleDate(dt: Date, amFormat = false): string {
    if (Date.now() - dt.getTime() < 24 * 60 * 60 * 1000) {
      if (amFormat) return BaseApp.formatAMPM(dt);

      // const tzoffset = (new Date()).getTimezoneOffset() * 60000;
      let result = BaseApp.formatAMPM(dt);
      const pieces = result.split(":");
      result = pieces[0] + pieces[1].substring(2, 10);
      /*
      const localISOTime = (new Date(dt.getTime() - tzoffset)).toISOString().slice(0, -1);
      let response = localISOTime.substring(11, 16);
      if (response.substring(0, 1) === "0") response = response.replace("0", " ");
      */
      return result;
    }

    return dt.toLocaleDateString("en-us", {
      month: "short",
      day: "numeric",
    });
  }
  /** return am pm format for date
   * @param { Date } date date to return format string
   * @return { string }
  */
  static formatAMPM(date: Date): string {
    let hours: any = date.getHours();
    let minutes: any = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes + " " + ampm;
  }
  /** convert isodate to local date as Date Object
   * @param { string } startTimeISOString iso date GMT referenced
   * @return { Date } JS Date object with date in local time zone reference
   */
  static isoToLocal(startTimeISOString: string): Date {
    const startTime = new Date(startTimeISOString);
    const offset = startTime.getTimezoneOffset();
    return new Date(startTime.getTime() - (offset * 60000));
  }
  /** return mm/dd/yy for Date or String passed in
   * @param { any } d Date(d) is parsed
   * @return { string } mm/dd/yy string value
   */
  static shortShowDate(d: any): string {
    d = new Date(d);
    if (isNaN(d)) return "";
    const str = d.toISOString().substr(0, 10);
    const mo = str.substr(5, 2);
    const ye = str.substr(2, 2);
    const da = str.substr(8, 2);
    return `${mo}/${da}/${ye}`;
  }
  /** update all time_since spans in this container
   * @param { any } container dom element to query for spans
   * @param { boolean } useGmailStyle true to return gmail style past date
   */
  updateTimeSince(container: any, useGmailStyle = false) {
    const elements = container.querySelectorAll(".time_since");
    elements.forEach((ctl: any) => {
      const isoTime = ctl.dataset.timesince;
      const showSeconds = ctl.dataset.showseconds;

      let dateDisplay: string;
      if (useGmailStyle) {
        dateDisplay = BaseApp.showGmailStyleDate(new Date(isoTime));
      } else {
        dateDisplay = this.timeSince(new Date(isoTime), (showSeconds === "1")).replaceAll(" ago", "");
      }
      BaseApp.setHTML(ctl, dateDisplay);
    });
  }
  /** escape html
   * @param { any } str  raw string to escape
   * @return { string } escaped string
  */
  static escapeHTML(str: any): string {
    if (str === undefined || str === null) str = "";
    str = str.toString();
    return str.replace(/[&<>'"]/g,
      (match: any) => {
        switch (match) {
          case "&": return "&amp;";
          case "<": return "&lt;";
          case ">": return "&gt;";
          case "'": return "&#39;";
          case "\"": return "&quot;";
        }

        return match;
      });
  }
  /** set html only if different
   * @param { any } ctl dom element to set html
   * @param { string } html innerHtml
   * @return { boolean } true if set
   */
  static setHTML(ctl: any, html: string): boolean {
    if (ctl.innerHTML !== html) {
      ctl.innerHTML = html;
      return true;
    }
    return false;
  }
  async saveProfileField(fieldKey: string, value: any) {
    if (!this.profile) return;
    const docRef = doc(getFirestore(), `Users/${this.uid}`);
    await setDoc(docRef, {
      [fieldKey]: value,
    }, {
      merge: true,
    });
  }
  /**
   *
   * @param { number } x incoming number
   * @param { number } decimalDigits number of decimals (toFixed()) -1 for ignore
   * @return { string } number with commas
   */
  static numberWithCommas(x: number, decimalDigits = 0): string {
    if (isNaN(Number(x))) x = 0;
    const xString = (decimalDigits !== -1) ? x.toFixed(decimalDigits) : x.toString();
    return xString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  /** display name and image
   * @param { string } uid user id
   * @param { string } docid session id
   * @return { any } name, imagePath in a map
  */
  userMetaFromDocument(uid: string, docid = ""): any {
    let doc: any;
    if (docid) doc = this.documentsLookup[docid];
    else if (this.sessionDocumentData) doc = this.sessionDocumentData;

    let imagePath = "";
    if (doc) imagePath = doc.memberImages[uid];
    if (this.uid === uid) imagePath = this.profile.displayImage;
    if (!imagePath) imagePath = "/images/solid_face_circle.svg";

    let name = "";
    if (doc) name = doc.memberNames[uid];
    if (this.uid === uid) name = this.profile.displayName;
    if (!name) name = "New User";

    return {
      imagePath,
      name,
    };
  }
  /** query dom for all member images names and update */
  updateUserNamesImages() {
    const imgCtls = document.querySelectorAll(".member_profile_image");
    const nameCtls = document.querySelectorAll(".member_profile_name");

    imgCtls.forEach((imgCtl: any) => {
      const uid: any = imgCtl.getAttribute("uid");
      const docid: any = imgCtl.getAttribute("docid");
      const userMeta = this.userMetaFromDocument(uid, docid);
      if ("url(" + userMeta.imagePath + ")" !== imgCtl.style.backgroundImage) {
        imgCtl.style.backgroundImage = "url(" + userMeta.imagePath + ")";
      }
    });

    nameCtls.forEach((nameCtl: any) => {
      const uid: any = nameCtl.getAttribute("uid");
      const docid: any = nameCtl.getAttribute("docid");
      BaseApp.setHTML(nameCtl, this.userMetaFromDocument(uid, docid).name);
    });
  }
  /**
   * @param {string } email email to test
   * @return { boolean } true if valid email
   */
  static validateEmail(email: string): boolean {
    const result = String(email)
      .toLowerCase()
      .match(
        /* eslint-disable-next-line max-len */
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (result) return true;
    return false;
  }
  /**
 * @param {string } emailList email to test
 * @return { boolean } true if valid email
 */
  static validateEmailList(emailList: string): boolean {
    emailList = emailList.replaceAll("\n", "");
    emailList = emailList.replaceAll("\r", "");
    const emails = emailList.trim().split(";");
    let invalidEmail = false;
    emails.forEach((email: string) => {
      if (!BaseApp.validateEmail(email)) invalidEmail = true;
    });
    if (invalidEmail) return false;
    return true;
  }
  /**
   *
   * @param { string } html string to strip tags from
   * @return { string } text without tags
   */
  static stripHtml(html: string) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  /**  On page load, unless on help page, set the day mode based on user preference
   * @return { number } 1 for dark mode, 0 for day
  */
  static initDayMode(): number {
    const niteMode = localStorage.getItem("niteMode");
    let themeIndex = 0;
    if (niteMode !== "true") {
      themeIndex = 0;
      document.body.classList.add("day_mode");
      document.body.classList.remove("nite_mode");
    } else {
      themeIndex = 1;
      document.body.classList.remove("day_mode");
      document.body.classList.add("nite_mode");
    }

    return themeIndex;
  }
  /** Toggle night mode when the checkbox is changed
   * @param { boolean } niteMode true if nite mode
  */
  toggleDayMode(niteMode = false) {
    if (niteMode) {
      localStorage.setItem("niteMode", "true");
    } else {
      localStorage.setItem("niteMode", "false");
    }
    this.themeIndex = BaseApp.initDayMode();
  }
  /**
   * @return { string }
   */
  uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c: any) =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
  /**
* @param { string } value text fragment
* @return { any } length and token array
*/
  getEncodedToken(value: any): any {
    let str = "";
    if (value !== undefined) str = value;
    if (!this.tokenizedStringCache[str]) {
      this.tokenizedStringCache[str] = encode(str);
      if (!this.tokenizedStringCache[str]) this.tokenizedStringCache[str] = [];
    }

    return this.tokenizedStringCache[str];
  }
  /** signout of firebase authorization  */
  async authSignout() {
    if (getAuth().currentUser) {
      // this.removeUserPresenceWatch();
      await signOut(getAuth());

      this.uid = null;

      if (!this.showLoginModal) window.location.reload();
      else window.location.href = "/";
    }
  }
  async processPromptWithAPI(message: string): Promise<any> {
    let resultMessage = 'unknown error';
    let promptResult: any = {};
    let error = true;

    try {
      const token = await getAuth().currentUser?.getIdToken() as string;
      const response = await fetch(this.basePath + "winnyAPI/llmmessage", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          token,
        },
        body: JSON.stringify({
          message,
        }),
      });
      const json = await response.json();
      if (json.success) {
        resultMessage = json?.llmResult?.candidates[0]?.content?.parts[0]?.text;
        if (!resultMessage) resultMessage = "No response from AI.";
      } else {
        if (json.errorMessage) resultMessage = json.errorMessage;
      }
    } catch (err: any) {
      console.log("error", err);
      if (err.message) resultMessage = err.message;
      error = err;
    }

    return {
      resultMessage,
      originalPrompt: message,
      promptResult,
      error,
    }
  }
  static generatePagination(totalItems: number, currentEntryIndex: number, itemsPerPage: number, currentPageIndex: number) {
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    let paginationHtml = '';
    paginationHtml = '<ul class="pagination pagination-sm mb-0">';
    paginationHtml += `
    <li class="page-item ${currentPageIndex === 0 || totalPages === 0 ? "buttondisabled" : ""}">
        <a class="page-link" href="#" aria-label="Previous" data-entryindex="-1">
            <span aria-hidden="true">&laquo;</span>
        </a>
    </li>
    <li class="page-item ${currentEntryIndex === 0 ? 'buttondisabled' : ''}">
      <a class="page-link" href="#" data-entryindex="-10">
          <span aria-hidden="true">
           &#x2190; 
          </span>
      </a>
    </li>`;
    const startIndex = currentPageIndex * itemsPerPage;
    const endIndex = Math.min((currentPageIndex + 1) * itemsPerPage, totalItems);
    for (let i = startIndex; i < endIndex; i++) {
      paginationHtml += `<li class="page-item ${currentEntryIndex === i ? 'selected' : ''}">
        <a class="page-link" href="#" data-entryindex="${i}">
            <span aria-hidden="true">${i + 1}</span>
        </a>
    </li>`;
    }
    paginationHtml += `
    <li class="page-item ${currentEntryIndex === totalItems - 1 ? 'buttondisabled' : ''}">
      <a class="page-link" href="#" data-entryindex="-20">
        <span aria-hidden="true">&#x2192;</span>
      </a>
    </li>
    <li class="page-item ${currentPageIndex === totalPages - 1 || totalPages === 0 ? 'buttondisabled' : ''}">
        <a class="page-link" href="#" aria-label="Next" data-entryindex="-2">
            <span aria-hidden="true">&raquo;</span>
        </a>
    </li>`;
    paginationHtml += `<li class="page-item count">
               <span>${totalItems}<br>items</span></li>`;
    paginationHtml += '</ul>';

    return paginationHtml;
  }
  static handlePaginationClick(newIndex: number, totalItems: number, selectedIndex: number, itemsPerPage: number, pageIndex: number) {
    if (newIndex === -1) {
      pageIndex = Math.max(pageIndex - 1, 0);
      if (selectedIndex < pageIndex * itemsPerPage) {
        selectedIndex = pageIndex * itemsPerPage;
      } else if (selectedIndex > (pageIndex + 1) * itemsPerPage - 1) {
        selectedIndex = pageIndex * itemsPerPage;
      }
    } else if (newIndex === -2) {
      pageIndex = Math.min(pageIndex + 1, Math.ceil(totalItems / itemsPerPage) - 1);
      if (selectedIndex < pageIndex * itemsPerPage) {
        selectedIndex = pageIndex * itemsPerPage;
      } else if (selectedIndex > (pageIndex + 1) * itemsPerPage - 1) {
        selectedIndex = pageIndex * itemsPerPage;
      }
    } else if (newIndex === -10) {
      selectedIndex -= 1;
      pageIndex = Math.floor(selectedIndex / itemsPerPage);
    } else if (newIndex === -20) {
      selectedIndex += 1;
      if (selectedIndex > totalItems - 1) selectedIndex = totalItems - 1;
      if (selectedIndex < 0) selectedIndex = 0;
      pageIndex = Math.floor(selectedIndex / itemsPerPage);
    } else {
      selectedIndex = newIndex;
      pageIndex = Math.floor(selectedIndex / itemsPerPage);
    }

    return {
      selectedIndex,
      pageIndex,
    }
  }
  /**
  Toggles the necessary aria- attributes' values on the menus
  and handles to show or hide them.
  @param {HTMLElement} element The menu link or button.
  @param {Boolean} show Whether to show or hide the menu.
  @param {Number} top Top offset in pixels where to show the menu.
*/
  toggleMenu(element: HTMLElement, show: Boolean, top: Number = 0) {
    const ariaControls = element.getAttribute('aria-controls');
    const target = ariaControls ? document.getElementById(ariaControls) : null;

    if (target) {
      element.setAttribute('aria-expanded', show ? 'true' : 'false');
      target.setAttribute('aria-hidden', !show ? 'true' : 'false');

      if (typeof top !== 'undefined') {
        target.style.top = top + 'px';
      }

      if (show) {
        target.focus();
      }
    }
  }
  /**
    Attaches event listeners for the menu toggle open and close click events.
    @param {HTMLElement} menu The menu container element.
  */
  setupContextualMenu(menu: HTMLElement) {
    const toggle = menu.querySelector('.p-contextual-menu__toggle') as HTMLButtonElement;
    const dropdown = menu.querySelector('.p-contextual-menu__dropdown') as HTMLDivElement;

    toggle?.addEventListener('click', (event: Event) => {
      event.preventDefault();
      const menuAlreadyOpen = toggle.getAttribute('aria-expanded') === 'true';

      let top = toggle?.offsetHeight - 15;
      // for inline elements leave some space between text and menu
      if (window.getComputedStyle(toggle).display === 'inline') {
        top += 5;
      }

      this.toggleMenu(toggle, !menuAlreadyOpen, top);
    });

    // Add handler for clicking outside the menu.
    document.addEventListener('click', (e: Event) => {
      const dropDownClicked = dropdown.contains(e.target as Node);
      const dropButtonClicked = toggle.contains(e.target as Node);
      if (!dropDownClicked && !dropButtonClicked) {
        this.toggleMenu(toggle, false);
      }
    });

    //Add event listener to close menu when tab focus leaves
    dropdown.addEventListener('focusout', (e: Event) => {
      // Check if where you have tabbed to is in the dropdown
      if (dropdown.contains((<any>e).relatedTarget)) return;

      this.toggleMenu(toggle, false);
    });

    // Add handler for closing menus using ESC key.
    document.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        this.toggleMenu(toggle, false);
      }
    });
  }
  /**
    Toggles visibility of modal dialog.
    @param {HTMLElement} modal Modal dialog to show or hide.
    @param {HTMLElement} sourceEl Element that triggered toggling modal
    @param {Boolean} open If defined as `true` modal will be opened, if `false` modal will be closed, undefined toggles current visibility.
  */
  toggleModal(modal: HTMLElement, sourceEl: HTMLElement | null, open: Boolean | null = null) {
    let currentDialog: HTMLElement | null = null;
    let lastFocus: HTMLElement | null = null;
    let ignoreFocusChanges = false;
    let focusAfterClose: HTMLElement | null = null;

    // Traps the focus within the currently open modal dialog
    function trapFocus(event: Event) {
      if (ignoreFocusChanges) return;

      if (currentDialog?.contains(event.target as Node)) {
        lastFocus = event.target as HTMLElement;
      } else {
        focusFirstDescendant(currentDialog as HTMLElement);
        if (lastFocus == document.activeElement) {
          focusLastDescendant(currentDialog as HTMLElement);
        }
        lastFocus = document.activeElement as HTMLElement;
      }
    }

    // Attempts to focus given element
    function attemptFocus(child: HTMLElement) {
      if (child.focus) {
        ignoreFocusChanges = true;
        child.focus();
        ignoreFocusChanges = false;
        return document.activeElement === child;
      }

      return false;
    }

    // Focuses first child element
    function focusFirstDescendant(element: HTMLElement) {
      for (let i = 0; i < element.childNodes.length; i++) {
        let child = element.childNodes[i] as HTMLElement;
        if (attemptFocus(child) || focusFirstDescendant(child)) {
          return true;
        }
      }
      return false;
    }

    // Focuses last child element
    function focusLastDescendant(element: HTMLElement) {
      for (let i = element.childNodes.length - 1; i >= 0; i--) {
        let child = element.childNodes[i] as HTMLElement;
        if (attemptFocus(child) || focusLastDescendant(child)) {
          return true;
        }
      }
      return false;
    }

    if (modal && modal.classList.contains('p-modal')) {
      if (open === null) {
        open = modal.style.display === 'none';
      }

      if (open) {
        currentDialog = modal;
        modal.style.display = 'block';
        focusFirstDescendant(modal);
        focusAfterClose = sourceEl;
        document.addEventListener('focus', trapFocus, true);
      } else {
        modal.style.display = 'none';
        if (focusAfterClose && (<any>focusAfterClose).focus) {
          (<any>focusAfterClose).focus();
        }
        document.removeEventListener('focus', trapFocus, true);
        currentDialog = null;
      }
    }
  }

  // Find and hide all modals on the page
  closeModals() {
    var modals = [].slice.apply(document.querySelectorAll('.p-modal'));
    modals.forEach((modal) => {
      this.toggleModal(modal, null, false);
    });
  }
}
