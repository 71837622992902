import BaseApp from "./baseapp";
import JournalHelper from "./journalhelper";
import AdjustTabHelper from "./adjusttabhelper";
import MetricHelper from "./metrichelper";
import Mustache from 'mustache';
import SlimSelect from 'slim-select';
import AnalysisResult from './../components/analysisresult.jsx';
import {
    createRoot,
} from "react-dom/client";
import React from "react";
import Split from 'split.js';
import JournalManager from "./journalmanager";

/** app class for content pages */
export class MainApp extends BaseApp {
    journalHelper = new JournalHelper(this);
    adjustTabHelper = new AdjustTabHelper(this);
    journalManager = new JournalManager(this);
    metrics_tab_id = document.getElementById("metrics_tab_id") as HTMLDivElement;
    analysis_set_select = document.querySelector(".analysis_set_select") as HTMLSelectElement;
    reset_profile_button = document.querySelector(".reset_profile_button") as HTMLButtonElement;
    settings_logout_anchor = document.querySelector(".settings_logout_anchor") as HTMLAnchorElement;
    write_main_panel = document.querySelector('.write_main_panel') as HTMLDivElement;
    metrics_side_panel = document.querySelector('.metrics_side_panel') as HTMLDivElement;
    metrics_side_split_panel = document.querySelector('.metrics_side_split_panel') as HTMLDivElement;
    viewSplitter = Split([this.write_main_panel, this.metrics_side_split_panel],
        {
            sizes: [50, 50],
            direction: 'horizontal',
            minSize: 100,
            gutterSize: 8,
        });
    metricHelper: MetricHelper | null = null;
    analysisResultDisplay: React.FunctionComponentElement<any>;
    analysisRunning = false;
    previousSlimOptions = "";
    lastSlimSelections = "";
    lastResultCache = "";
    analysisSetsSlimSelect = new SlimSelect({
        select: '.analysis_set_select',
        settings: {
            showSearch: false,
            placeholderText: 'Select Analysis Set(s)',
            keepOrder: true,
            hideSelected: true,
            minSelected: 1,
            closeOnSelect: false,
        },
        events: {
            afterChange: async () => {
                const selectedAnalysisSets: any[] = [];
                this.analysisSetsSlimSelect.render?.main.values.querySelectorAll('.ss-value')
                    .forEach((item: any) => {
                        selectedAnalysisSets.push(item.innerText);
                    });
                if (selectedAnalysisSets.length <= 1) {
                    this.analysis_set_select.classList.add('slimselect_onevalue');
                } else {
                    this.analysis_set_select.classList.remove('slimselect_onevalue');
                }

                this.saveProfileField("selectedAnalysisSets", selectedAnalysisSets);
            },
        },
    });
    debouncedInputTimeouts: any = {};
    tabKeys: any = {
        left: 'ArrowLeft',
        right: 'ArrowRight',
    };
    tabDirection: any = {
        "ArrowLeft": -1,
        "ArrowRight": 1,
    };
    metricTypes = ["score 0-10", "text", "json"];
    generaticMetricPromptTemplate = `Edit the prompt example template based on the following description. Only return the new prompt template. Do not include the description or example template in the response.
Description for new prompt template: 
{{description}}
    
Example prompt template:
{{exampleTemplate}}`;

    /** */
    constructor() {
        super();
        this.showLoginModal = false;
        this.analysisResultDisplay = React.createElement(AnalysisResult, {
            hooks: {},
        });
        this.analysisResultDisplay.props.hooks.processRawResultstoCompact =
            (analysisResults: any[]) => this.processRawResultstoCompact(analysisResults);
        createRoot(this.metrics_side_panel).render(this.analysisResultDisplay);

        this.reset_profile_button.addEventListener('click', async () => {
            await this._authCreateDefaultProfile();
        });
        this.settings_logout_anchor.addEventListener('click', async (e) => {
            e.preventDefault();
            this.authSignout();
        });
        this.initTabs('[role="tablist"]');
        this.loadMain();
    }
    async loadMain() {
        await this.loadHTMLTemplate("/pages/metrics.html?abc=1233", this.metrics_tab_id);

        this.metricHelper = new MetricHelper(this);
    }
    /** override event that happens after authentication resolution */
    authUpdateStatusUI(): void {
        super.authUpdateStatusUI();
        if (this.profile) {
            this.journalHelper.load();
            this.adjustTabHelper.load();
            this.paint();
        }
    }
    attachEvents(tabs: any[]) {
        tabs.forEach((tab, index) => {
            tab.addEventListener('keyup', (e: KeyboardEvent) => {
                if (e.code === this.tabKeys.left || e.code === this.tabKeys.right) {
                    this.switchTabOnArrowPress(e, tabs);
                }
            });

            tab.addEventListener('click', (e: Event) => {
                e.preventDefault();
                this.setActiveTab(tab, tabs);
            });

            tab.addEventListener('focus', () => {
                this.setActiveTab(tab, tabs);
            });

            tab.index = index;
        });
    }
    setActiveTab(tab: any, tabs: any[]) {
        tabs.forEach((tabElement: HTMLDivElement) => {
            const tabContent = document.getElementById(tabElement.getAttribute('aria-controls') as string) as HTMLElement;

            if (tabElement === tab) {
                tabElement.setAttribute('aria-selected', "true");
                tabContent.removeAttribute('hidden');
            } else {
                tabElement.setAttribute('aria-selected', "false");
                tabContent.setAttribute('hidden', "true");
            }
        });
    }
    initTabs(selector: string) {
        var tabContainers = [].slice.call(document.querySelectorAll(selector));

        tabContainers.forEach((tabContainer: HTMLElement) => {
            const tabs = [].slice.call(tabContainer.querySelectorAll('[aria-controls]'));
            this.attachEvents(tabs);
        });
    }
    switchTabOnArrowPress(e: KeyboardEvent, tabs: any[]) {
        const pressed = e.code as string;

        if (this.tabDirection[pressed]) {
            const target = e?.target as any;
            if (target.index !== undefined) {
                if (tabs[target.index + this.tabDirection[pressed]]) {
                    tabs[target.index + this.tabDirection[pressed]].focus();
                } else if (pressed === this.tabKeys.left) {
                    tabs[tabs.length - 1].focus();
                } else if (pressed === this.tabKeys.right) {
                    tabs[0].focus();
                }
            }
        }
    }
    async loadHTMLTemplate(path: string, dom: any) {
        let htmlRequest = await fetch(path);
        let html = await htmlRequest.text();
        dom.innerHTML = html;
    }
    async getDefaultAnalysisPrompts() {
        const promptListFile = await fetch("/defaults/promptDefaultsList.json");
        const defaultPromptList = await promptListFile.json();
        const promises: any[] = [];
        defaultPromptList.forEach((url: string) => {
            promises.push((async (url) => {
                let promptQuery = await fetch("/defaults/" + url + ".json");
                let defaultPrompts = await promptQuery.json();
                const allPrompts: any[] = [];
                defaultPrompts.forEach((prompt: any) => {
                    prompt.setName = url;
                    allPrompts.push(prompt);
                });
                return allPrompts;
            })(url));
        });
        const defaultPrompts = await Promise.all(promises);
        const resultPrompts: any[] = [];
        defaultPrompts.forEach((promptList, index) => {
            promptList.forEach((prompt: any) => {
                resultPrompts.push(prompt);
            });
        });
        return resultPrompts;
    }
    async getAnalysisPrompts() {
        let metrics = await this.getDefaultAnalysisPrompts();
        const json = this.profile.masterAnalysisList || "";
        let masterAnalysisList: any[] = [];
        if (json) masterAnalysisList = JSON.parse(json);
        if (masterAnalysisList.length > 0) {
            metrics = masterAnalysisList;
        }
        return metrics;
    }
    async getAnalysisSetNames() {
        let allPrompts = await this.getAnalysisPrompts();
        let analysisSets: any = {};
        allPrompts.forEach((prompt) => {
            if (!analysisSets[prompt.setName]) {
                analysisSets[prompt.setName] = [];
            }
            analysisSets[prompt.setName].push(prompt);
        });

        return Object.keys(analysisSets);
    }
    async generateMetricTemplate(exampleTemplate: string, description: string) {
        let promptTemplate = this.profile.generateMetricPromptTemplate || "";
        if (!promptTemplate) promptTemplate = this.generaticMetricPromptTemplate;

        const result = Mustache.render(promptTemplate, { exampleTemplate, description });
        let newPromptContent = (await this.processPromptWithAPI(result)).resultMessage;
        return newPromptContent;
    }
    async setFieldToStorage(domInput: HTMLInputElement | HTMLTextAreaElement, storageKey: string) {
        let value = domInput.value;

        await this.saveProfileField(storageKey, value);
    }
    static processOptions(options: string): any {
        const opts = options.split("||");
        const optionsMap: any = {};
        opts.forEach((opt: string) => {
            const pieces = opt.trim().split("=");
            const key = pieces[0].trim();
            if (key !== "") {
                let value = "";
                if (pieces.length > 1) value = pieces.slice(1).join("=").trim();

                optionsMap[key] = value;
            }
        });

        return optionsMap;
    }
    async renderSlimSelect() {
        const setNames = await this.getAnalysisSetNames();
        let html = "";
        setNames.forEach((setName) => {
            html += `<option value="${setName}">${setName}</option>`;
        });
        const selectedAnalysisSets = this.profile.selectedAnalysisSets || [];
        let slimOptions: any[] = [];
        setNames.forEach((setName) => {
            slimOptions.push({ text: setName, value: setName });
        });
        const slimOptionsString = JSON.stringify(slimOptions);
        let dataChange = false;
        if (this.previousSlimOptions !== slimOptionsString) {
            this.analysisSetsSlimSelect?.setData(slimOptions);
            this.previousSlimOptions = slimOptionsString;
            dataChange = true;
        }

        if (selectedAnalysisSets) {
            let setCache = JSON.stringify(selectedAnalysisSets);
            if (setCache !== this.lastSlimSelections || dataChange) {
                this.lastSlimSelections = setCache;
                this.analysisSetsSlimSelect?.setSelected(selectedAnalysisSets);
                let domSelections = this.analysisSetsSlimSelect?.render?.main.values.querySelectorAll('.ss-value') as NodeListOf<HTMLElement>;
                let indexMap: any = {};
                domSelections.forEach((item: any, index: any) => {
                    indexMap[item.innerText] = index;
                });
                let setOrder = selectedAnalysisSets;
                setOrder.forEach((setName: any, index: any) => {
                    let domIndex = indexMap[setName];
                    if (domSelections[domIndex]) {
                        this.analysisSetsSlimSelect?.render?.main.values.appendChild(domSelections[domIndex]);
                    }
                });
            }
        }
        if (this.analysisSetsSlimSelect?.getSelected().length === 0) {
            this.analysisSetsSlimSelect.setSelected([setNames[0]]);
        }
    }
    paint() {
        this.renderSlimSelect();
        this.journalHelper.paint();
        this.adjustTabHelper.paint();
/*
        const newResultCache = JSON.stringify(this.profile.lastAnalysisResult);
        if (newResultCache !== this.lastResultCache) {
            if (this.profile.lastAnalysisResult) {
                this.analysisResultDisplay.props.hooks.setHistoryEntry(this.profile.lastAnalysisResult);
                this.analysisResultDisplay.props.hooks.setShow(true);
            } else {
                this.analysisResultDisplay.props.hooks.setShow(false);
            }
        }
*/
    }
    processRawResultstoCompact(analysisResults: any[]) {
        let compactData: any[] = [];
        analysisResults.forEach((urlResult: any) => {
            let compactResult: any = {};
            compactResult.url = urlResult.url;
            compactResult.title = urlResult.title;

            const results = urlResult.results;
            if (results) {
                results.forEach((metricResult: any) => {
                    const fieldName = metricResult.prompt.name + "_" + metricResult.prompt.setName;
                    if (metricResult.prompt.metricType === "score 0 - 10") {
                        let metric = 0;
                        try {
                            let json = JSON.parse(metricResult.result.resultMessage);
                            metric = json.contentRating;
                        } catch (e) {
                            metric = -1;
                        }
                        compactResult[fieldName] = metric;
                    } else {
                        compactResult[fieldName] = metricResult.result.resultMessage;
                    }
                });
            } else {
                compactResult["No Results"] = "No Results";
            }

            compactData.push(compactResult);
        });

        if (compactData.length > 0) {
            const firstRow = compactData[0];
            const allFields: any = {};
            compactData.forEach((row: any) => {
                Object.keys(row).forEach((field) => {
                    allFields[field] = true;
                });
            });
            const fieldNames = Object.keys(allFields);
            fieldNames.forEach((fieldName) => {
                if (!firstRow[fieldName]) {
                    firstRow[fieldName] = "";
                }
            });
        }
        return compactData;
    }
    async getFieldFromProfile(domInput: HTMLInputElement | HTMLTextAreaElement, storageKey: string, defaultStorageKey = "") {
        clearTimeout(this.debouncedInputTimeouts[storageKey]);
        const getFieldValue = async () => {
            let value = this.profile[storageKey];
            if (!value && defaultStorageKey) value = this.profile[defaultStorageKey] || "";
            return value;
        }
        const value = await getFieldValue();
        if (value === domInput.value) return;
        if (!this.debouncedInputTimeouts[storageKey] && !domInput.value && value) {
            domInput.value = value || "";
        }
        this.debouncedInputTimeouts[storageKey] = setTimeout(async () => {
            const value = await getFieldValue();
            if (domInput.value !== value) domInput.value = value || "";
        }, 500);
    }
}
