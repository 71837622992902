import React from "react";

export default class FooterBar extends React.Component {
    constructor() {
        super();
        this.state = {
            tokenCount: 0,
        };
    }
    render() {
        return (<div className="loggedout_inline_block text-center p-2">
            &copy;2025
            <div className="ml-3 inline-block">
                <a className="loggedout_inline_block text-blue-500 visited:text-blue-500" href="#" onClick={this.props.hooks.googleLogin}>Login</a>
                &nbsp;
                <a className="loggedin_inline_block text-blue-500 visited:text-blue-500" href="#" onClick={this.props.hooks.signOut}>Logout</a>
            </div>
        </div >);
    }
}